declare global {
  interface Window {
    API_URL: string;
  }
}

const BASE_URL: string = `${window.location.protocol}//${window.location.host}`;
const OCR_BASE_URL: string = `https://pic2data.azurewebsites.net`;
// const BASE_URL: string = `${window.location.protocol}//moneto-backend.test`;
// const OCR_BASE_URL: string = `https://localhost:5001`

class ConfigSingleton {
  public get monetoApiUrl(): string {
    let url = window.API_URL;
    
    if (!url) {
      url = `${window.location.host}/api/`;
    } 
    else if (url === '<prod>') {
      url = `${BASE_URL}/api`;
    }

    if (url.startsWith('/')) {
      url = `${window.location.host}${url}`;
    }

    return `${url}`;
  }

  public get scanUrl(): string {
    return `${BASE_URL}/api/ocr`;
    // return `${window.location.protocol}//${window.location.host}/api/ocr`;
  }

  public get ocrProcessUrl(): string {
    const incomeSwissreEmployee: string|null = localStorage.getItem('incomeSwissreEmployee');
    
    if(incomeSwissreEmployee == "1") {
      return `${BASE_URL}/api/ocr-service`;
    }

    return `${OCR_BASE_URL}/OCRMVP/PROCESSING`;
    // return `https://monetodev.gs-services.ch/api/ocr/upload`;
    // return `https://monetoscs.azurewebsites.net/OCRMVP/Processing`; // swissre url
    // return "https://certisnap.azurewebsites.net/OCRMVP/PROCESSING"; // very initial url
    // return `https://certisnap.azurewebsites.net/OCRMVPIR/ProcessingHub`; // live moneto url
  }

  public get ocrHubUrl(): string {
    if (
      window.location.host.indexOf('test.moneto.ch') >= 0 ||
      window.location.host.indexOf('debug.moneto.ch') >= 0 ||
      window.location.host.indexOf('azurewebsites') >= 0 ||
      window.location.host.indexOf('localhost') >= 0
    ) {
      return 'https://certisnap-test.azurewebsites.net/OCRMVPIR/ProcessingHub';
    } else {
      return `${OCR_BASE_URL}/OCRMVPIR/ProcessingHub`;
    }
  }

  public get postcodesUrl(): string {
    return `${window.location.protocol}//api.las.ag/de/postcode/`;
  }

  public get googleAnalyticsUID(): string {
    return 'UA-92848928-2';
  }
}

export const config = new ConfigSingleton();
