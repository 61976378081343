import { getEncrypted } from '../pages/login/page/loginEncrypt';
import { Account } from '../redux/types';
import authProvider from '../providers/authProvider';
import { config } from '../config';
import accountProvider from '../providers/accountProvider';
import decode from "jwt-decode";
import moment from 'moment';

export interface LoginResponse {
  errors: Array<any>;
  error: any;
  mode: 'pro' | 'easy';
  sid: string;
  accept: number;
  factors: number;
  unlocker: string | undefined;
  user_id: number;
  tok: string;
}

const authService = {
  tryToLogin(
    login: string,
    password: string,
    callback: (x: Account | LoginResponse) => void,
    error: (err: any) => void,
    action: string = 'login'
  ) {
    const hash: string = getEncrypted(password);
    // after login, not directed to dashboard
    authProvider.login(
      login,
      hash,
      (res: LoginResponse) => {
        console.log('normal login rs: ', res);
        //zour in wrong place, login dont have issue, after login on getDossier its not routing, I know
        if (res.errors && res.errors.length > 0) {
          error(res.errors[0]);
        } else if (res.factors > 1) {
          callback(res);
        } else if (res.user_id > 0) {
          this.getUserInfo(res.sid, (x: Account) => callback({ ...x, mode: res.mode }), error);
        } else {
          error(res.error);
        }
      },
      error,
      action
    );
  },
  secondFactor(session: string, token: string, code: string, callback: (x: Account) => void, error: (err: any) => void) {
    authProvider.loginSecondFactor(
      session,
      token,
      code,
      (res: LoginResponse) => {
        if (res.accept !== 1) {
          error('InvalidCode');
        } else if (res.user_id > 0 && res.accept === 1) {
          this.getUserInfo(session, callback, error);
        } else {
          error(res.error);
        }
      },
      (err: any) => error(err)
    );
  },
  getUserInfo(session: string | null, callback: (x: any) => void, error: (x: any) => void) {
    accountProvider.getUserInfo(
      session,
      (res: { account: Account }) => {
        if (!this.isCookiePresented()) this.setSessionToStorage(session || '');
        if (res && res.account) callback(res.account);
        else error('Server error');
      },
      (err: any) => error(err)
    );
  },
  handleLogin(callback: (x: any) => void, error: (x: any) => void) {
    let session: string | null = this.isLoggedIn()
      ? this.getSessionFromStorage()
      : getCookie(window.location.hostname.replace(/\./g, '_'));
    if (session) this.getUserInfo(session, callback, error);
  },
  isLoggedIn() {
    return (
      this.getSessionFromStorage() !== null &&
      this.getSessionFromStorage() !== undefined &&
      this.getSessionFromStorage() !== ''
    );
  },
  logout(callback: () => void) {
      accountProvider.logout(()=> {
        callback();
        this.forgetJwt();
        this.removeSessionFromStorage();
        document.cookie =
        window.location.hostname.replace(/\./g, '_') + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        // CLEAR ALL INTERVALS AFTER LOGOUT
        for(let i=0; i<100; i++)
        {
          window.clearInterval(i);
        }
    });
  },
  isCookiePresented() {
    const cookieName: string = window.location.hostname.replace(/\./g, '_');
    let session: string | null = getCookie(cookieName);

    if (session) return true;
    return false;
  },
  setSessionToStorage(value: string) {
    localStorage.setItem('session', value);
  },
  getSessionFromStorage(): string | null {
    if(!this.getJwt()){
      return null;
    }
    let session: string | null = getCookie(window.location.hostname.replace(/\./g, '_'));
    if (!session) session = localStorage.getItem('session');
    return session;
  },
  removeSessionFromStorage() {
    localStorage.removeItem('session');
  },
  redirectToProMode() {
    window.location.href = config.monetoApiUrl;
  },
  saveJwt(jwt: string){
    sessionStorage.setItem('jwt', jwt);
  },
  forgetJwt(){
    sessionStorage.removeItem('jwt');
  },
  getJwt(){
    const jwt = sessionStorage.getItem('jwt');
    if(!jwt) return null;
    try{
      const {exp}: any = decode(jwt);
      if(!exp){
        throw new Error("Exp not found");
      }
      const now = moment().unix();
      if(now > exp){ // if expired
        throw new Error("JWT expired");
      }
      return jwt;
    } catch(e){
      console.log(e);
      // something went wrong, we remove jwt
      this.logout(()=>{
        console.log(`Logged out due to malformed jwt`);
      });
    }
    return null;
  },
  syncInProgress: false,
  forceSyncConfirmed: false,
  syncDossier(reason: string) {}
};

const getCookie = (name: string) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

export default authService;