import React from 'react';
import Styled from 'styled-components';
import { KFormCard, KRadio, KNumber, KSelect, KDatePicker } from '../../../components/KForm';
import { KFormRow, KFormCol, KFormSection } from '../../../components/KForm/KForm_styles';
import { Card_Props, ProgressResult_Props } from './../../module.interfaces';
import { GeneralText, CardHeading, CardQuestion } from '../../../components/common/styled';
import KFieldArray from '../../../components/KForm/KFieldArray';
import useCardValidation from '../../../utils/useCardValidation';
import { useStore } from 'react-redux';
import { Trans } from 'react-i18next';
import moment from 'moment';
import createGUID from '../../../utils/guidGenerator';
import SimpleValidator from '../../../utils/validator';
import isPersonSwissreeEmployee from '../../../utils/isPersonSwissreeEmployee';
import AppConstants from '../../../constants/appConstants';


const UL = Styled.ul`
padding-left: 40px;
list-style-type: disc;
> li {
  margin-top: 10px;
}
`

class CardPensionWorkUVG {
  constructor() {
    this.name = '';
    this.validate = (legalData: any, mode: number, person: any) => {
      let result: ProgressResult_Props = { done: false, invalid: 0, total: 0 };
      if (!legalData) return result;
      if( person && person.legal && person.legal.finances && person.legal.finances.incomeType && person.legal.finances.incomeType[0] === 3 ){
        result.done = true; return result;
      }
      result.total = 1;
      if (legalData.uvgDetailed && legalData.uvgDetailed.length > 0) {
        if (legalData.uvgDetailed.indexOf(2) > -1) {
          legalData.uvgAccident = [];
          legalData.uvgDisablity = '';
          result.total += 1;
          if (legalData.uvgCut && legalData.uvgCut.length > 0){
            result.total += 1;
            if(legalData.uvgCut.indexOf(2) > -1){
              if(legalData.uvgContract && legalData.uvgContract.length > 0){
                result.total += 1;
                if(legalData.uvgContract.indexOf(1) > -1){
                  legalData.uvgStart = '';
                  if(!legalData.uvgBranch || !legalData.uvgBranch.length ) result.invalid++;
                }else{
                  legalData.uvgBranch = [];
                  if(!legalData.uvgStart) result.invalid++;
                }
              }else{
                legalData.uvgBranch = [];
                legalData.uvgStart = ''; 
                result.invalid++;
              }
            }else{
              legalData.uvgContract = [];
              legalData.uvgBranch = [];
              legalData.uvgStart = ''; 
            }
          }else{
            legalData.uvgContract = [];
            legalData.uvgBranch = [];
            legalData.uvgStart = '';
            result.invalid++; 
          }
        } else if (legalData.uvgDetailed.indexOf(1) > -1){ 
          legalData.uvgCut = [];
          legalData.uvgContract = [];
          legalData.uvgBranch = [];
          legalData.uvgStart = '';
          result.total += 2;
          if( legalData.uvgAccident && legalData.uvgAccident.length > 0){
            legalData.uvgAccident.forEach((e:any)=>{
              result.total += 3;
              if( !e.from ) result.invalid++;
              if( !e.till ) result.invalid++;
              if( !e.percent ) result.invalid++;
            });
          }else{
            result.invalid++
          }

          if(person.legal.finances.incomeSwissreEmployee && person.legal.finances.incomeSwissreEmployee[0] == 1) {
            if(!moment(legalData.uvgStartDateSwissre, AppConstants.DATE_FORMAT).isValid()) {
              result.invalid++;
            }
          }
        }
      } else {
        result.invalid++;
      }

      result.done = result.invalid === 0;
      return result;
    };
    this.card = CardComponent;
  }
  name: string;
  card: React.FC<Card_Props>;
  validate: (legalData: any, mode: number, person: any) => ProgressResult_Props;
}

export default CardPensionWorkUVG;

const CardComponent: React.FC<Card_Props> = (props: Card_Props) => {
  const { legalData, cardName, target, t } = props;
  const state = useStore().getState();
  const person = props.target && state[props.target];

  useCardValidation(props, person);

  let hasIncome = false;
  if( person && person.legal && person.legal.finances && person.legal.finances.incomeType && person.legal.finances.incomeType[0] !== 3 ){
    hasIncome = true;
  }

  const isSwissre = isPersonSwissreeEmployee(person);

  const uvgDetailSelectionArray = isSwissre ? [0] : [0,1,2];

  return (
    <>
      {legalData && hasIncome ? (
        <KFormCard
          id={props.id}
          cardName={cardName}
          cardNumber={props.cardNumber}
          state={props.isCardValid}
          disabled={props.disabled}
          handleApprove={props.handleApprove}
          toggleHelp={props.toggleHelp}
          position={props.position}
          closedText={ legalData.uvgDetailed && t(`${cardName}.detailedItems.${legalData.uvgDetailed[0]-1}`) 
          }
        >
          <KFormSection>
            <KFormRow>
              <KFormCol width={1}>
              <CardHeading>{t(`${cardName}.title`)}</CardHeading>
              </KFormCol>
            </KFormRow>
            <KFormRow>
              <KFormCol width={1}>
                <KRadio
                  name={'uvgDetailed'}
                  label={t(`${cardName}.detailed`)}
                  noLabel
                  itemsName={uvgDetailSelectionArray.map((e) => {
                    return t(`${cardName}.detailedItems.${e}`);
                  })}
                  itemsValue={uvgDetailSelectionArray.map((number: number) => number + 1) }
                  fieldValue={legalData.uvgDetailed}
                  setFieldValue={props.setFieldValue}
                />
              </KFormCol>
            </KFormRow>

            {legalData.uvgDetailed && legalData.uvgDetailed.indexOf(2) > -1 ? (
              <>
              <KFormRow>
                <KFormCol width={1}>
                  <CardQuestion>{t(`${cardName}.cut`)}</CardQuestion>
                </KFormCol>
              </KFormRow>
              <KFormRow>
                <KFormCol width={1}>
                  <KRadio
                    name={'uvgCut'}
                    label={t(`${cardName}.cut`)}
                    noLabel
                    itemsName={[0, 1].map((e) => {
                      return t(`${cardName}.cutItems.${e}`);
                    })}
                    itemsValue={[1, 2]}
                    fieldValue={legalData.uvgCut}
                    setFieldValue={props.setFieldValue}
                  />
                </KFormCol>
              </KFormRow>

              { legalData.uvgCut && legalData.uvgCut.indexOf(2) > -1 ? (
                <>
                <KFormRow>
                  <KFormCol width={1}>
                    <CardQuestion>{t(`${cardName}.contract`)}</CardQuestion>
                  </KFormCol>
                </KFormRow>
                <KFormRow>
                  <KFormCol width={1}>
                    <KRadio
                      name={'uvgContract'}
                      label={t(`${cardName}.contract`)}
                      noLabel
                      itemsName={[0, 1].map((e) => {
                        return t(`${cardName}.contractItems.${e}`);
                      })}
                      itemsValue={[1, 2]}
                      fieldValue={legalData.uvgContract}
                      setFieldValue={props.setFieldValue}
                    />
                  </KFormCol>
                </KFormRow>
                { legalData.uvgContract && legalData.uvgContract.indexOf(1) > -1 ? (
                  <>
                  <KFormRow>
                    <KFormCol width={1 / 2} force={true}>
                      <KSelect
                        name={'uvgBranch'}
                        label={t(`${cardName}.branch`)}
                        itemsName={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((e) => {
                          return t(`${cardName}.branchItems.${e}`);
                        })}
                        itemsValue={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                        fieldValue={legalData.uvgBranch}
                        setFieldValue={props.setFieldValue}
                      />
                    </KFormCol>
                  </KFormRow>
                  </>
                ) : '' }
                { legalData.uvgContract && legalData.uvgContract.indexOf(2) > -1 ? (
                  <>
                  <KFormRow>
                    <KFormCol width={1}>
                      <CardQuestion>{t(`${cardName}.start`)}</CardQuestion>
                    </KFormCol>
                  </KFormRow>
                  <KFormRow>
                    <KFormCol width={1 / 2} force={true}>
                      <KDatePicker
                        name={'uvgStart'}
                        label={t(`${cardName}.startDate`)}
                        fieldValue={legalData.uvgStart}
                        setFieldValue={props.setFieldValue}
                      />
                    </KFormCol>
                  </KFormRow>
                  </> 
                ) : '' }
              </> ) : '' }
            </> ) : '' }

            { legalData.uvgDetailed && legalData.uvgDetailed.indexOf(1) > -1 && 
              <RegistrationAccordingToEmploymentContract {...props} isSwissre={isSwissre} /> }

          </KFormSection>
        </KFormCard>
      ) : (
        <KFormCard
          id={props.id}
          cardName={props.cardName}
          cardNumber={props.cardNumber}
          state={true}
          disabled={props.disabled}
          handleApprove={props.handleApprove}
          toggleHelp={props.toggleHelp}
          position={props.position}
          closedText={ t(`${cardName}.noIncome`) }
        >
          <GeneralText>
            { t(`${cardName}.noIncomeMessage`) }
          </GeneralText>
        </KFormCard>
      )}
    </>
  );
};


const RegistrationAccordingToEmploymentContract = (props: any) => {
  const { legalData, cardName, t, isSwissre } = props;
  
  const validator = new SimpleValidator();
  
  const setUVG = (name:string, array:any[]) => {
    props.setFieldValue(name, array);
  }

  const calculateInCaseOfIllnesSwissre = (dateOfSwissreStart: string) => {
    const dateOfStart = moment(dateOfSwissreStart, AppConstants.DATE_FORMAT);
    const uvgIllnesArray = new Array;

    if(!dateOfStart.isValid()) {
      return 
    }

    const YEAR = 360;
    const TOTAL_DAYS_OF_WORK = moment().diff(dateOfStart, 'days');

    if(TOTAL_DAYS_OF_WORK <= 2 * YEAR) {
      uvgIllnesArray.push({
        id: createGUID(),
        from: 1,
        till: YEAR / 2,
        percent: 100
      })
    } 
    else if (TOTAL_DAYS_OF_WORK > 2 * YEAR && TOTAL_DAYS_OF_WORK <= 11 * YEAR) {
      uvgIllnesArray.push({
        id: createGUID(),
        from: 1,
        till: YEAR,
        percent: 100
      })
    } 
    else if (TOTAL_DAYS_OF_WORK > 11 * YEAR) {
      uvgIllnesArray.push({
        id: createGUID(),
        from: 1,
        till: 540,
        percent: 100
      })
    }

    setUVG('uvgIllness', uvgIllnesArray);
    setUVG('uvgAccident', uvgIllnesArray); // Same applies to the UVG Accident in case of swissre
  }
  
  return (
    <>
    { isSwissre && 
      <KFormRow>
        <KFormCol>
          <CardQuestion>
            <Trans>
              {t(`${cardName}.detailedQuestion`) }
            </Trans>

            <UL>
              {
                t(`${cardName}.detailedQuestionHelpList`, {
                  returnObjects: true
                })
                .map((title: string) => <li key={title}>{title}</li>)
              }
            </UL>
            <br />
            <Trans>
              {t(`${cardName}.detailedQuestion_2`)}
            </Trans>
          </CardQuestion>
        </KFormCol>
      </KFormRow>
    }

    {
      isSwissre && 
      <KFormRow>
        <KFormCol width={1 / 2} force={true}>
          <KDatePicker
            name={'uvgStartDateSwissre'}
            format={AppConstants.DATE_FORMAT}
            validations={'required|date'}
            validator={validator}
            label={t(`${cardName}.dateOfJoiningTheCompany`)}
            fieldValue={legalData.uvgStartDateSwissre}
            setFieldValue={(name, date) => {
              props.setFieldValue(name, date)
              calculateInCaseOfIllnesSwissre(date);
            }} />
        </KFormCol>
      </KFormRow>
    }
    {(!isSwissre || moment(legalData.uvgStartDateSwissre, AppConstants.DATE_FORMAT).isValid()) &&  
    <>
    <KFieldArray
      name={'uvgIllness'}
      label={t(`${cardName}.illness`)}
      addText={t(`${cardName}.add`)}
      disabled={isSwissre}
      setFieldValue={setUVG}
      fieldValue={legalData.uvgIllness}>
      <KFormRow>
        <KFormCol width={1 / 3} force={true}>
          <KNumber
            name={'from'}
            label={t(`${cardName}.from`)}
          />
        </KFormCol>
        <KFormCol width={1 / 3} force={true}>
          <KNumber
            name={'till'}
            label={t(`${cardName}.till`)}
          />
        </KFormCol>
        <KFormCol width={1 / 3} force={true}>
          <KNumber
            name={'percent'}
            label={t(`${cardName}.percent`)}
            isPercent
          />
        </KFormCol>
      </KFormRow>
    </KFieldArray>
    
    <KFieldArray
      name={'uvgAccident'}
      label={t(`${cardName}.accident`)}
      disabled={isSwissre}
      addText={t(`${cardName}.add`)}
      setFieldValue={setUVG}
      fieldValue={legalData.uvgAccident}>
      <KFormRow>
        <KFormCol width={1 / 3} force={true}>
          <KNumber
            name={'from'}
            label={t(`${cardName}.from`)}
          />
        </KFormCol>
        <KFormCol width={1 / 3} force={true}>
          <KNumber
            name={'till'}
            label={t(`${cardName}.till`)}
          />
        </KFormCol>
        <KFormCol width={1 / 3} force={true}>
          <KNumber
            name={'percent'}
            label={t(`${cardName}.percent`)}
            isPercent
          />
        </KFormCol>
      </KFormRow>
    </KFieldArray> 

    <KFormRow>
      <GeneralText><Trans>{t(`${cardName}.disablity`)}</Trans></GeneralText>
      <GeneralText><Trans>{t(`${cardName}.disablityMessage`)}</Trans></GeneralText>
    </KFormRow>
    
    </>
    }
  </> 
  )
}
