import React from 'react';

import Styled from 'styled-components';
import { EmptyCard_Props } from '../../module.interfaces';
import { WhiteBox, Row, Col, GeneralText, RectangleIcon, Heading3, Heading5 } from '../../../components/common/styled';

import theme from '../../../assets/theming';
import showNum from '../../../utils/showNum';

export const PENSIONS_PLANUNG = "pensionsplanung";
export const ERWERBSUNFAHIGKEIT = "erwerbsunfahigkeit";
export const TODESFALL = "todesfall";

const CardRecommend: React.FC<EmptyCard_Props> = (props: EmptyCard_Props) => {
    const {t, data, mode} = props;

    const gateHole = data && data.hole.total < 1;
    const title = gateHole ? t(`facts.checkTitle`) : mode === 1 ? t(`facts.saveTitle`) : t(`facts.insureTitle`);   
    const text = gateHole ? t(`facts.checkText`,  { need: data && showNum(data.need.month) }) 
    : mode === 1 ? t(`facts.saveText`,{ month: data && showNum(data.hole.saveMonth), need: data && showNum(data.need.month) }) 
    : t(`facts.insureText`, { need: data && showNum(data.need.month) } );

    return( 
        <WhiteBox>
            <Row>
                <Col layout={1}>
                    <Heading3>{ t(`facts.title`) }</Heading3>
                    <GeneralText noMargin>
                        {t(`global:please_be_aware_that_the_tool_shall_give_you_a_better_understanding_of_your_financial_situation_but_it_does_not_replace_the_professional_advice_of_a_financial_or_tax_advisory_service`)}
                    </GeneralText>
                    <Row>
                        <div style={{marginRight:'20px'}}>
                            <RectangleIcon 
                                content={'money6'}
                                color={theme.analyzer.diagramLabel}
                                backgroundColor={gateHole ? theme.analyzer.diagramCheck : theme.analyzer.diagramNeed} />
                        </div>
                        <div style={{flex:'1'}}>
                            <Heading5>{title}</Heading5>
                            <GeneralText noMargin>{text}</GeneralText>
                        </div>
                    </Row>
                    <InformationText {...props}/>
                </Col>
            </Row>
        </WhiteBox>
    );
}

const InformationText = (props: any) => { 
    const { t } = props;
    
    const currentApp = () => {
        if(window.location.pathname.indexOf(PENSIONS_PLANUNG) > -1) {
            return PENSIONS_PLANUNG;
        }
        else if(window.location.pathname.indexOf(ERWERBSUNFAHIGKEIT) > -1) {
            return ERWERBSUNFAHIGKEIT;
        }
        else if(window.location.pathname.indexOf(TODESFALL) > -1) {
            return TODESFALL;
        }
        return null;
    }
    
    let extraInformationTexts:Array<string> = t(`global:death_result_page_list`, {
        returnObjects: true
    });
    const title = t(`global:title_result_page_list`);

    if(currentApp() == PENSIONS_PLANUNG) {
        extraInformationTexts = t(`global:pension_planning_result_page_list`, {
            returnObjects: true
        });
    }

    if(currentApp() == ERWERBSUNFAHIGKEIT) {
        extraInformationTexts = t(`global:disability_to_work_result_page_list`, {
            returnObjects: true
        });
    }

    return (
        <Row>
            <div style={{marginRight:'20px'}}>
                <RectangleIcon 
                    content={'info'}
                    color={theme.analyzer.diagramLabel}
                    backgroundColor={theme.card.blockBorder} />
            </div>
            <div style={{flex:'1'}}>
                <Heading5>{title}</Heading5>
                <UL>
                    {extraInformationTexts.map((title: string) => <li key={title}>{title}</li>)}
                </UL>
            </div>
        </Row>
    )
}

const UL = Styled.ul`
padding-left: 40px;
list-style-type: disc;
> li {
  margin-top: 10px;
}
`



export default CardRecommend;