import httpService from '../services/httpService';
import initialState, { Dossier } from '../redux/initialState';
import authService from '../services/authService';
import { Account } from '../redux/types';
import { httpActions } from '../constants/enums';

interface DossierProvider {
  closeDossier: (callback: (x: any) => void, error: (x: any) => void) => void;
  getDossierInfo: (callback: (x: any, ownDossier: boolean) => void, error: (x: any) => void) => void;
  updateDossierInfo: (dossier: Dossier, callback: (x: number) => void, error: (x: any) => void) => void;
}

const dossierProvider: DossierProvider = {
  getDossierInfo(callback: (x: any, ownDossier: boolean) => void, error: (x: any) => void) {
    httpService.get(
      '',
      {
        action: httpActions.GET_DOSSIER
      },
      (res: {
        dossier: {
          data: string;
          changed: number;
          ownDossier: boolean;
        };
        specialist: Account | undefined;
        error: string;
      }) => {
        if (res.error) {
          error(res.error);
        } else {
          // console.log('issue here res: ', res);
          const dossier: Dossier = res && res.dossier && res.dossier.data ? JSON.parse(res.dossier.data) : initialState;
          dossier.sync = { timestamp: res.dossier.changed, manualTimestamp: res.dossier.changed };
          dossier.specialist = res.specialist || new Account();
          callback(dossier, res.dossier.ownDossier);
        }
      },
      error
    );
  },
  updateDossierInfo(dossier: Dossier, callback: (x: any) => void, error: (x: any) => void) {
    const fd = new FormData();
    fd.append('action', httpActions.UPDATE_DOSSIER);
    fd.append('dossier', JSON.stringify(dossier));
    fd.append('force', `${authService.forceSyncConfirmed}`);
    httpService.post(
      '',
      fd,
      (res: any) => {
        if (res.success && res.timestamp > 0) {
          callback(res.timestamp);
        } else {
          error(res);
        }
      },
      error
    );
  },
  closeDossier(callback: (x: any) => void, err: (x: any) => void) {
    httpService.get('', { action: httpActions.CLOSE_DOSSIER }, callback, err);
  }
};

export default dossierProvider;
