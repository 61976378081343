import axios, { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import { config } from '../config';
import authService from './authService';
import { httpActions } from '../constants/enums';
export const client: AxiosInstance = axios.create({
  baseURL: config.monetoApiUrl
});

/**
 * We include session id to every request only if
 *  - user is logged in and
 *  -- cookie is absent
 *  -- request is not made to OCR endpoints
 */
 client.interceptors.request.use(request=>{
  const {url = ''} = request;
  const cookiePresent =  authService.isCookiePresented();
  const ocrEndpoint = url.indexOf(config.ocrProcessUrl) > -1;
  if(authService.isLoggedIn() && !(cookiePresent || ocrEndpoint)){
    request.params = {
      ...request.params,
      session: authService.getSessionFromStorage()
    }
  }
  return request;
});

/**
 * By default, we include the jwt in every request if available
 */
client.interceptors.request.use(request=>{
  const jwt = authService.getJwt();
  if(jwt){
    request.headers.Authorization = `Bearer ${jwt}`;
  }
  return request;
});

/**
 * Modify request to include common props
 */
 client.interceptors.request.use(request=>{
  const lang = localStorage.getItem('language') || 'de';
  request.params = {
    ...request.params,
    lang // query param to include lang prop
  };

  // need to convert action to api end point i.e. url for axios 

  const method = (request.method || '').toLocaleLowerCase();
  
  let url = request.url || ''; // initial url value

  if(['get', 'delete'].indexOf(method) > -1 && request.params.action){ // for get or delete, action should be in params
    url = request.params.action;
  }

  // for post, it can be found in different places
  if(['post', 'put', 'patch'].indexOf(method) > - 1){
    if(request.params.action){ // can be found in params
      url = request.params.action;
    } else if(request.data && request.data instanceof FormData && request.data.get('action')){ // can be found in form data
      url = request.data.get('action') as string;
    } else if(request.data && request.data.action){ // can be found as json
      url = request.data.action;
    } else if(typeof request.data === 'string'){ // data is a string, maybe stringified json
      try{
        const {action} = JSON.parse(request.data); // parse string to find action in it
        if(action){ 
          url = action;
        }
      } catch(e){}
    }
  }
  request.url = url;
  return request;
});

/**
 * An interceptor to intercept any response with jwt and save that jwt
 * TODO : We may restrict this interceptor to work with certain urls only
 */

client.interceptors.response.use(response=>{
  if(response && response.headers && response.headers.token){
    authService.saveJwt(response.headers.token as string);
  }
  return response;
});

const request = async (options: AxiosRequestConfig) => {
  const onSuccess = (response: any) => response.data;

  const onError = (error: any) => {
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error);
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

const httpService = {
  get: (
    url: string,
    data: any,
    callback: (x: any) => void,
    error: (x: string) => void | undefined,
    cancelToken?: CancelToken | undefined
  ) => {
    return request({
      url: `${url}`,
      method: 'GET',
      headers: GetAuthHeader(url),
      cancelToken: cancelToken,
      params: data
    })
      .then(callback)
      .catch(error);
  },
  post: (
    url: string,
    data: any,
    callback: (x: any) => void,
    error: (x: string) => void | undefined,
    cancelToken?: CancelToken
  ) => {
    return request({
      url: url,
      method: 'POST',
      data: data,
      headers: GetAuthHeader(url),
      cancelToken: cancelToken
    })
      .then(callback)
      .catch(error);
  },
  put: (
    url: string,
    data: any,
    callback: (x: any) => void,
    error: (x: string) => void | undefined,
    cancelToken?: CancelToken
  ) => {
    return request({
      url: url,
      method: 'PUT',
      data: data,
      headers: GetAuthHeader(url),
      cancelToken: cancelToken
    })
      .then(callback)
      .catch(error);
  },
  delete: (
    url: string, 
    data: any,
    callback: (x: any) => void,
    error: (x: string) => void | undefined,
    cancelToken?: CancelToken
  ) => {
    return request({
      url: `${url}`,
      method: 'DELETE',
      headers: GetAuthHeader(url),
      cancelToken: cancelToken,
      params: data
    })
      .then(callback)
      .catch(error);
  },
  patch: (
    url: string,
    data: any,
    callback: (x: any) => void,
    error: (x: string) => void | undefined,
    cancelToken?: CancelToken
  ) => {
    return request({
      url: url,
      method: 'PATCH',
      data: data,
      headers: GetAuthHeader(url),
      cancelToken: cancelToken
    })
      .then(callback)
      .catch(error);
  }
};

const GetAuthHeader = (url: string) => {
  if (url && url.startsWith(config.ocrProcessUrl)) {
    return { SessionId: 'valid-session-id' };
  } else return {};
};

export default httpService;
