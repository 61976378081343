import { getEncrypted } from '../pages/login/page/loginEncrypt';
import httpService from '../services/httpService';
import { config } from '../config';
import authService from '../services/authService';
import { httpActions } from '../constants/enums';


const accountProvider = {
  getUserInfo(session: string | null, callback: (x: any) => void, error: (x: any) => void) {
    httpService.get(
      config.monetoApiUrl,
      {
        action: httpActions.REQUEST_USER_DATA,
        session: authService.isCookiePresented() ? undefined : session
      },
      callback,
      error
    );
  },
  logout(callback: () => void) {
    const data = new FormData();
    data.set('action', httpActions.LOGOUT);
    httpService.post(
      config.monetoApiUrl,
      data,
      callback,
      () => {}
    );
    // callback();
  },
  changePassword(oldPassword: string, newPassword: string, callback: (res: any) => void, err: (err: any) => void) {
    let fd: FormData = new FormData();
    fd.append('old', getEncrypted(oldPassword));
    fd.append('pass', getEncrypted(newPassword));
    fd.append('action', httpActions.CHANGE_PASSWORD);
    httpService.post(config.monetoApiUrl, fd, callback, err);
  },
  changeEmail(password: string, email: string, code: string, callback: (res: any) => void, err: (err: any) => void) {
    let fd: FormData = new FormData();
    fd.append('mail', email);
    fd.append('code', code);
    fd.append('pass', getEncrypted(password));
    fd.append('action', httpActions.CHANGE_EMAIL);
    httpService.post(config.monetoApiUrl, fd, callback, err);
  },
  change2faAndPhone(
    twofa: number,
    password: string,
    phone: string,
    callback: (res: any) => void,
    err: (err: any) => void
  ) {
    let fd: FormData = new FormData();
    fd.append('twofa', String(twofa));
    fd.append('phone', phone);
    fd.append('pass', getEncrypted(password));
    fd.append('action', httpActions.CHANGE_AUTH_TYPE);
    httpService.post(config.monetoApiUrl, fd, callback, err);
  }
};

export default accountProvider;
