const SwissreConstants = {
    benefit_100_percent: {
        type: 1001,
        color: '#3f51b5',
        category_name: 'continuation_of_wage_payment'
    },
    benefit_70_percent: {
        type: 1002,
        color: '#3f51b5',
        category_name: 'wage_replacement_benefit'
    },
    bvg_uvg_disability_benefit: {
        type: 1003,
        color: '#3f51b5',
        category_name: 'bvg_uvg_disability_benefit'
    },
    bvg_uvg_death_benefit: {
        type: 1003,
        color: '#3f51b5',
        category_name: 'bvg_uvg_death_benefit'
    }
}

export default SwissreConstants;