import React, { useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/styles';
import { hot } from 'react-hot-loader/root';

import Routes from './routes.container';
import store, { history } from '../redux/store';
import ToastComponent from '../components/common/toast';
import { CloseSnackIcon } from '../components/common/styled';
import muiTheme from '../assets/themeProvider';

import '../styles/App.css';
import '../utils/i18n';

export const App: React.FC = () => {
  const notistackRef = React.createRef<any>();

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      const vh: number = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <Provider store={store}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={7}
          transitionDuration={250}
          autoHideDuration={2500}
          action={(key) => (
            <CloseSnackIcon
              onClick={() => notistackRef && notistackRef.current && notistackRef.current.closeSnackbar(key)}
            />
          )}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <ConnectedRouter key={Math.random() /* <- makes routes work after a hot-reload occurs*/} history={history}>
            <ToastComponent />
            <Routes history={history} />
          </ConnectedRouter>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
