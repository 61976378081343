import React, { Suspense, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import ReactGA from 'react-ga';

import Header from '../components/common/header';
import { MainComponent } from '../components/common/styled';
import authService from '../services/authService';
import { Dossier } from '../redux/initialState';
import {
  setLogin,
  changeMyPerson,
  changeMyPartner,
  changeMyConnection,
  changeMyPartnerState,
  changeLoadedState,
  changeAccount,
  changeSpecialist,
  updateSyncTimestamp,
  clearKey,
  setApplications,
  updateSyncTimestampManual
} from '../redux/actions';
import { Account } from '../redux/types';
import { unsecureComponents, loggedInComponents, loggedOutComponents, RouteWithLayoutProps } from './routes';
import dossierProvider from '../providers/dossierProvider';
import useEffectOnlyOnce from '../utils/useEffectOnlyOnce';
import { useMemoizedCallback } from '../utils/customCallbackHook';
import { config } from '../config';

const NotFoundPage = React.lazy(() => import('../pages/notFound'));

type Props = {
  children: any;
  history: any;
};

export const MainLayout: React.FC<Props> = ({ children, history }) => (
  <MainComponent>
    <Header history={history} />
    {children}
  </MainComponent>
);

let syncTimer: any;
const Routes: React.FC<any> = ({
  authInfo,
  loggedIn,
  isSpecialist,
  setLogin,
  changeLoadedState,
  changeMyConnection,
  setApplications,
  changeMyPartnerState,
  changeMyPartner,
  changeAccount,
  changeSpecialist,
  history,
  changeMyPerson,
  updateSyncTimestamp,
  updateSyncTimestampManual,
  clearKey,
  ownDossier
}) => {
  const { t } = useTranslation(['global']);
  const store = useStore();
  const location = useLocation();
  const isGAActive = false;

  const changeLoadedAndLoginState = useCallback(
    (loggedIn: boolean) => {
      setLogin(loggedIn);
      changeLoadedState(true);
    },
    [changeLoadedState, setLogin]
  );

  const updateDossier = useMemoizedCallback(
    (reason: string | undefined) => {
      if (authService.syncInProgress) {
        return;
      }
      authService.syncInProgress = true;
      try {
        dossierProvider.updateDossierInfo(
          store.getState(),
          (timestamp: number) => {
            authService.forceSyncConfirmed = false;
            if (reason) {
              updateSyncTimestampManual(timestamp);
              updateSyncTimestamp(timestamp);
            } else {
              updateSyncTimestamp(timestamp);
            }
            let state = store.getState();

            if (
              state.sync.timestamp &&
              state.sync.manualTimestamp &&
              state.sync.timestamp - state.sync.manualTimestamp > 1800
            ) {
              clearInterval(syncTimer);
              authService.logout(() => {
                setLogin(false);
                clearKey(0);
                clearKey(1);
                clearKey(2);
                window.alert(t('IdleTimeout-question'))
              });
            }

            authService.syncInProgress = false;
          },
          (error: any) => {
            authService.forceSyncConfirmed = false;
            if (error && error.error === 'DossierDataExpired') {
              clearInterval(syncTimer);
              if (window.confirm(t('DossierDataExpired-question'))) {
                /* TODO: What's the sense in this self-assign, probably cleaner way to do it */
                document.location = document.location; // eslint-disable-line no-self-assign
              } else {
                authService.logout(() => {
                  setLogin(false);
                  clearKey(0);
                  clearKey(1);
                  clearKey(2);
                });
              }
            } else if (error && error.error === 'DossierOpenedByAnotherUser') {
              clearInterval(syncTimer);
              if (window.confirm(t('DossierOpenedByAnotherUser-question'))) {
                authService.forceSyncConfirmed = true;
                authService.syncInProgress = false;
                authService.syncDossier('confirmation');
              } else {
                authService.logout(() => {
                  setLogin(false);
                  clearKey(0);
                  clearKey(1);
                  clearKey(2);
                });
              }
            } else if (error && error.error === 'DossierOpenedByUser') {
              clearInterval(syncTimer);
              if (window.confirm(t('DossierOpenedByUser-question'))) {
                authService.forceSyncConfirmed = true;
                authService.syncInProgress = false;
                authService.syncDossier('confirmation');
              } else {
                dossierProvider.closeDossier(
                  (res: any) => {
                    authService.redirectToProMode();
                  },
                  (err: any) => console.log(err)
                );
              }
            } else if (error && error.error === 'DossierOpenedByConsultant') {
              clearInterval(syncTimer);
              if (window.confirm(t('DossierOpenedByConsultant-question'))) {
                authService.forceSyncConfirmed = true;
                authService.syncInProgress = false;
                authService.syncDossier('confirmation');
              } else {
                authService.logout(() => {
                  setLogin(false);
                  clearKey(0);
                  clearKey(1);
                  clearKey(2);
                });
              }
            } else if (error && error.error === 'DossierOpenedByAnotherConsultant') {
              clearInterval(syncTimer);
              if (window.confirm(t('DossierOpenedByAnotherConsultant-question'))) {
                authService.forceSyncConfirmed = true;
                authService.syncInProgress = false;
                authService.syncDossier('confirmation');
              } else {
                dossierProvider.closeDossier(
                  (res: any) => {
                    authService.redirectToProMode();
                  },
                  (err: any) => console.log(err)
                );
              }
            }

            authService.syncInProgress = false;
          }
        );
      } catch (ex) {
        authService.syncInProgress = false;
      }
    },
    [clearKey, setLogin, store, t, updateSyncTimestamp, updateSyncTimestampManual]
  );

  authService.syncDossier = (reason: string) => {
    if (authInfo.loggedIn && !authService.syncInProgress) {
      clearInterval(syncTimer);
      updateDossier(reason);
      syncTimer = setInterval(updateDossier, 60000);
    }
  };

  const trackAnalytics = () => {
    ReactGA.pageview(window.location.href);
  }

  useEffect(() => {
    ReactGA.initialize(config.googleAnalyticsUID);
    // ReactGA.set({ anonymizeIp: true });
  }, [])

  useEffect(() => {
    authService.syncDossier('location');
    trackAnalytics();
  }, [location]);

  useEffect(() => {
    if (history.location.pathname.indexOf('/scan') > -1) {
    } else {
      if (authInfo.loggedIn) {
        setTimeout(() => {
          updateDossier('login');
        }, 5000);
        syncTimer = setInterval(updateDossier, 60000);
      } else {
        clearInterval(syncTimer);
      }
    }
    return () => {
      clearInterval(syncTimer);
    };
  }, [authInfo.loggedIn, history.location.pathname, updateDossier]);

  useEffectOnlyOnce(() => {
    const error = (x: any) => {
      if (x === 'DossierNotFoundPro' || x === 'DossierNotAccess' || x === 'ProDossier') {
        authService.redirectToProMode();
      } else {
        authService.logout(() => changeLoadedAndLoginState(false));
      }
    };

    if (history.location.pathname.indexOf('/scan') < 0 && authService.isLoggedIn())
      authService.handleLogin((account: Account) => {
        dossierProvider.getDossierInfo((dossier: Dossier, own_dossier: boolean) => {
          changeAccount({ ...account, ownDossier: own_dossier });
          if (dossier) {
            if (dossier.myPerson) {
              changeMyPerson(dossier.myPerson);
            }
            if (dossier.myPartner) {
              changeMyPartner(dossier.myPartner);
              if (dossier.myPartner.active && dossier.myPerson.hasPartner.indexOf(1) > -1) changeMyPartnerState();
            }
            if (dossier.myConnection) {
              changeMyConnection(dossier.myConnection);
            }
            if (dossier.specialist) {
              changeSpecialist(dossier.specialist);
            }
          }
          setApplications(store.getState());
          changeLoadedAndLoginState(true);
          updateSyncTimestamp(dossier.sync.timestamp);
        }, error);
      }, error);
    else {
      changeLoadedAndLoginState(false);
    }
  });

  return (
    authInfo.isLoaded && (
      <MainLayout history={history}>
        <Suspense fallback={<div>{t('loading')}... </div>}>
          <Switch>
            {(loggedIn ? loggedInComponents : loggedOutComponents).map((item: RouteWithLayoutProps, index: number) =>
              !(isSpecialist && item.unavailableForSpec) || (item.availableForOwnDossier && ownDossier) ? (
                <Route exact key={index} {...item} />
              ) : (
                <Redirect to="/" />
              )
            )}
            {unsecureComponents().map((item: RouteWithLayoutProps, key: number) => (
              <Route exact key={100 + key} {...item} />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </MainLayout>
    )
  );
};

const mapStateToProps = (state: Dossier) => ({
  loggedIn: state.authInfo.loggedIn,
  isSpecialist: state.account.is_specialist,
  ownDossier: state.account.own_dossier,
  authInfo: state.authInfo
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLogin: (isLoggedIn: boolean) => dispatch(setLogin(isLoggedIn)),
    changeMyPerson: (x: any) => dispatch(changeMyPerson(x)),
    changeMyPartner: (x: any) => dispatch(changeMyPartner(x)),
    changeMyConnection: (x: any) => dispatch(changeMyConnection(x)),
    changeLoadedState: (x: boolean) => dispatch(changeLoadedState(x)),
    setApplications: (x: any) => dispatch(setApplications(x)),
    changeAccount: (x: any) => dispatch(changeAccount(x)),
    changeSpecialist: (x: any) => dispatch(changeSpecialist(x)),
    changeMyPartnerState: () => dispatch(changeMyPartnerState()),
    updateSyncTimestamp: (x: number) => dispatch(updateSyncTimestamp(x)),
    updateSyncTimestampManual: (x: number) => dispatch(updateSyncTimestampManual(x)),
    clearKey: (target: number) => dispatch(clearKey(target))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
