import { IkScanResultItem, IkScanResultItemValue } from "../../../app_modules/pensionState/modulePensionState";
import { pkStaticName, pkStaticValueKeys } from "../../../constants/enums";
import { dateFromString, firstNonZeroField, stripNonDigit } from "./pkAuszug.component";
import { DeathBenefitArrayItem, DisibilityPensionArrayItem, PkScanResult, RetirementBenefitItem } from "./pkAuszug.interfaces";

const extractDataFromResult = (result: any, personData: any, getField: Function, getFieldValue: Function) => {
    let newObject: PkScanResult = {} as PkScanResult;
    newObject.vertexes = {};

    newObject.vertexes.date = getField(`${pkStaticName.QUALIFYING_DATE}.${pkStaticValueKeys.DATE}`);
    newObject.date = newObject.vertexes.date.Value;

    newObject.vertexes.company = getField(`company`);
    newObject.company = newObject.vertexes.company.Value;
    
    newObject.vertexes.incomeyear = getField(`${pkStaticName.ANNUAL_SALARY}.${pkStaticValueKeys.TOTAL}`);
    newObject.incomeyear = newObject.vertexes.incomeyear.Value;

    newObject.vertexes.incomeinsured = getField(`${pkStaticName.INSURED_ANNUAL_SALARY}.${pkStaticValueKeys.TOTAL}`);
    newObject.incomeinsured = newObject.vertexes.incomeinsured.Value;
    
    if (!newObject.incomeinsured) {
      newObject.vertexes.incomeinsured = getField(
        `${pkStaticName.INSURED_ANNUAL_SALARY_SAVING}.${pkStaticValueKeys.TOTAL}`
      );
      newObject.incomeinsured = newObject.vertexes.incomeinsured.Value;
    }

    newObject.vertexes.workload = tryToGetValue(getField, pkStaticName.LEVEL_OF_EMPLOYMENT); 
    newObject.workload = parseFloat(newObject.vertexes.workload.Value);

    if (!newObject.workload) {
      newObject.vertexes.workload = getField(`${pkStaticName.LEVEL_OF_EMPLOYMENT}.${pkStaticValueKeys.PERCENT}`);
      newObject.workload = parseFloat(newObject.vertexes.workload.Value);
    }

    if (!newObject.workload) {
      newObject.vertexes.workload = getField(`${pkStaticName.LEVEL_OF_EMPLOYMENT}.${pkStaticValueKeys.DEFAULT}`);
      newObject.workload = parseFloat(newObject.vertexes.workload.Value);
    }

    newObject.vertexes.creditbvg = getField(`${pkStaticName.EXISTING_PENSION_ASSETS}.${pkStaticValueKeys.BVG}`);
    newObject.creditbvg = newObject.vertexes.creditbvg.Value;

    const item19 = getField(`${pkStaticName.RETIREMENT_ASSETS_PENSION_PLAN}.${pkStaticValueKeys.NUMBER}`).Value;
    const item21 = getField(`${pkStaticName.RETIREMENT_ASSETS_FOND}.${pkStaticValueKeys.NUMBER}`).Value;

    newObject.vertexes.creditabovebvg = getField(`${pkStaticName.EXISTING_PENSION_ASSETS}.${pkStaticValueKeys.ABOVE_BVG}`);
    newObject.creditabovebvg = newObject.vertexes.creditabovebvg.Value;

    newObject.vertexes.credittotal = getField(`${pkStaticName.EXISTING_PENSION_ASSETS}.${pkStaticValueKeys.TOTAL}`);
    newObject.credittotal = stripNonDigit(newObject.vertexes.credittotal.Value);
    if (!newObject.credittotal) {
      newObject.credittotal = parseFloat(stripNonDigit(item19) || '0') + parseFloat(stripNonDigit(item21) || '0');
    }

    if (!newObject.credittotal && newObject.date) {
      const cells = getFieldValue('RETIREMENT_ASSETS_ON.DATE', 'array').filter((cell: IkScanResultItem) =>
        cell.Values.find(
          (val: IkScanResultItemValue) => val.SystemName === pkStaticValueKeys.DATE && val.Value === newObject.date
        )
      );
      if (cells.length > 0) {
        const valEntry = cells[0].Values.find(
          (val: IkScanResultItemValue) => val.SystemName === pkStaticValueKeys.TOTAL
        );
        if (valEntry) {
          newObject.credittotal = valEntry;
        }
      }
    }

    if(newObject.credittotal){
      newObject.credittotal =  stripNonDigit(newObject.credittotal)
    }

    newObject.isChildAvailable = true;
    newObject.vertexes.dischild = firstNonZeroField(tryToGetValue(getField, pkStaticName.DIS_CHILD_RENT));
    newObject.dischild = newObject.vertexes.dischild.Value;
    newObject.dischildCheck = [1];

    newObject.vertexes.widowvalue = tryToGetValue(getField, pkStaticName.WIDOW_RENT);
    newObject.widowvalue = newObject.vertexes.widowvalue.Value;
    newObject.widowaccident = [1];

    newObject.annualIllnessWidowCheck = [1];

    newObject.vertexes.orphanvalue = tryToGetValue(getField, pkStaticName.ORPHAN_RENT);
    newObject.orphanvalue = newObject.vertexes.orphanvalue.Value;
    newObject.orphanaccident = [1];


    newObject.retirement = getRetirementBenefitTable(result, getField);
    if (!newObject.retirement || !newObject.retirement.length || newObject.retirement.length === 0) {
      // Table mapping failed. Looking for single rows
      newObject.retirement = [];
      newObject.vertexes.retirement = [];
      let obj: RetirementBenefitItem = {
        vertexes: {}
      } as RetirementBenefitItem;

      obj.vertexes.pensioncapital = firstNonZeroField(
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.TOTAL_WITH_INT}`),
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.TOTAL}`),
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.TOTAL_WITHOUT_INT}`),
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.TOTAL_NO_INT}`),
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.BVG_WITHOUT_INT}`)
      );
      obj.pensioncapital = obj.vertexes.pensioncapital.Value;

      obj.vertexes.pensionyear = firstNonZeroField(
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.ANNUAL_RENT_WITH_INT}`),
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.BVG_WITHOUT_INT}`),
        getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_ENDAGE}.${pkStaticValueKeys.ANNUAL_RENT_WITHOUT_INT}`),
        getField(`${pkStaticName.PROJECTED_ANNUAL_RENT_AT_ENDAGE}.${pkStaticValueKeys.TOTAL_WITH_INT}`),
        getField(`${pkStaticName.PROJECTED_ANNUAL_RENT_AT_ENDAGE}.${pkStaticValueKeys.TOTAL}`),
        getField(`${pkStaticName.PROJECTED_ANNUAL_RENT_AT_ENDAGE}.${pkStaticValueKeys.TOTAL_WITHOUT_INT}`),
        getField(`${pkStaticName.PROJECTED_ANNUAL_RENT_AT_ENDAGE}.${pkStaticValueKeys.TOTAL_NO_INT}`)
      );
      obj.pensionyear = obj.vertexes.pensionyear.Value;

      if (obj.pensioncapital || obj.pensionyear) {
        obj.vertexes.pensionage = getField(
          `${pkStaticName.PROJECTED_PENSION_ASSETS_AT_AGE}.${pkStaticValueKeys.AGE}`
        );
        obj.pensionage = obj.vertexes.pensionage.Value;
        if (!obj.pensionage) {
          // Fallback #1: Try to get gender from external Moneto/QC data.
          if (personData && personData.gender && !isNaN(personData.gender as any)) {
            if (personData.gender === 1)
              // Male.
              obj.pensionage = 65;
            else if (personData.gender === 1)
              // Female.
              obj.pensionage = 64;
          }
        }
        if (!obj.pensionage) {
          // Fallback #2: Extract Gender field from OCR form.
          let gender: any = getFieldValue(`${pkStaticName.GENDER}.${pkStaticValueKeys.DEFAULT}`);
          if (gender && gender.toLowerCase) {
            if (gender.toLowerCase().startsWith('m')) {
              obj.pensionage = 65;
            } else if (gender.toLowerCase().startsWith('f') || gender.toLowerCase().startsWith('w')) {
              obj.pensionage = 64;
            }
          }
        }
        if (!obj.pensionage) {
          // Fallback #3: Calculate retirement age based on extracted dates
          let birthdateString: any = getFieldValue(`${pkStaticName.BIRTHDATE}.${pkStaticValueKeys.DATE}`);
          let retirementAgeString: any = getFieldValue(`${pkStaticName.RETIREMENT_AGE}.${pkStaticValueKeys.DATE}`);
          let birthdateDate = dateFromString(birthdateString);
          let retirementAgeDate = dateFromString(retirementAgeString);
          if (birthdateDate && retirementAgeDate) {
            obj.pensionage = Math.round((retirementAgeDate - birthdateDate) / (1000 * 60 * 60 * 24 * 365.25));
          }
        }
      }

      if (obj.pensioncapital || obj.pensionyear || obj.pensionage) {
        newObject.retirement.push(obj);
      }
    }

    newObject.disability = getDisabilityPensionItems(result, getField);
    newObject.deathBenefit = getDeathBenefitItems(result, getField);

    return newObject; 
}

const getDisabilityPensionItems = (response:any, getField: Function) => {
    const count: number = getFieldsCount(response, pkStaticName.DIS_RENT);

    let result: Array<DisibilityPensionArrayItem> = [];
    for (let index = 0; index < count; index++) {
        let obj: DisibilityPensionArrayItem = {vertexes: {}} as DisibilityPensionArrayItem;

        obj.vertexes.disvalue = firstNonZeroField(tryToGetValue(getField, `${pkStaticName.DIS_RENT}.${index}`));
        obj.disvalue = obj.vertexes.disvalue.Value;
        
        obj.vertexes.diswait = firstNonZeroField(
            getField(`${pkStaticName.DIS_RENT}.${index}.${pkStaticValueKeys.XX}`),
            getField(`${pkStaticName.DIS_WAITING_DURATION}.${index}.${pkStaticValueKeys.XX}`));
        
        obj.diswait = obj.vertexes.diswait.Value;
        
        if(!obj.diswait) {
            obj.diswait = 24;
        }

        obj.disaccident = [1];

        result.push(obj);
    }

    return result;
}

const getDeathBenefitItems = (response: any, getField: Function) => {
    const count: number = getFieldsCount(response, pkStaticName.DEATH_CAPITAL);
    let result: Array<DeathBenefitArrayItem> = [];

    const illnesDeathCapital = getField(`${pkStaticName.DEATH_CAPITAL}.${pkStaticValueKeys.ILLNESS}`);
    const illnessObject: DeathBenefitArrayItem  = {
        deathcapital: illnesDeathCapital.Value,
        deathaccident: [],
        deathillness: [1],
        deathmarried: [],
        deathnotmarried: [],
        vertexes: {}
    }
    result.push(illnessObject);

    for (let index = 0; index < count; index++) {
      let obj: DeathBenefitArrayItem = {
        deathcapital: 0,
        deathaccident: [],
        deathillness: [],
        deathmarried: [],
        deathnotmarried: [],
        vertexes: {}
      } as DeathBenefitArrayItem;

      obj.vertexes.deathcapital = getField(`${pkStaticName.DEATH_CAPITAL}.${index}.${pkStaticValueKeys.ACCIDENT}`);
      obj.deathcapital = obj.vertexes.deathcapital.Value;
      obj.deathaccident = [1];
      obj.deathillness = [];
      result.push(obj);
    }

    return result;
}

const getFieldsCount = (response: any, name: string) => {
    return response.filter((item: IkScanResultItem) => item.SystemName === name).length;
}

const getRetirementBenefitTable = (response: any, getField: Function) => {
    const count: number = getTableFieldsCount(response, pkStaticName.PROJECTED_PENSION_ASSETS_AT_AGE);
    let resultBenefits: Array<RetirementBenefitItem> = [];

    for (let index = 0; index < count; index++) {
      let obj: RetirementBenefitItem = {
        vertexes: {}
      } as RetirementBenefitItem;
      obj.vertexes.pensionage = getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_AGE}.${index}.${pkStaticValueKeys.AGE}`);
      obj.pensionage = obj.vertexes.pensionage.Value;

      obj.vertexes.pensioncapital = getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_AGE}.${index}.${pkStaticValueKeys.TOTAL_WITH_INT}`);
      obj.pensioncapital = stripNonDigit(obj.vertexes.pensioncapital.Value);

      obj.vertexes.pensionyear = getField(`${pkStaticName.PROJECTED_PENSION_ASSETS_AT_AGE}.${index}.${pkStaticValueKeys.ANNUAL_RENT_WITH_INT}`);
      obj.pensionyear = stripNonDigit(obj.vertexes.pensionyear.Value);

      resultBenefits.push(obj);
    }
    resultBenefits.sort((a: RetirementBenefitItem, b: RetirementBenefitItem) => {
      return b.pensionage - a.pensionage;
    });
    return resultBenefits;
};

const getTableFieldsCount = (response: any, name: string) => {
    return response.filter((item: IkScanResultItem) => item.SystemName === name && (item.KeyType || '')
            .endsWith('Table')).length;
}

const tryToGetValue = (getField: Function, name: string) => {
    let object = getField(`${name}.${pkStaticValueKeys.NUMBER}`);

    if(object?.Value) {
        return object;
    }

    object =  getField(`${name}.${pkStaticValueKeys.ANNUAL_ILLNESS}`)
    if(object?.Value) {
        return object;
    }

    object = getField(`${name}.${pkStaticValueKeys.ANNUAL_TOTAL}`);
    if(object?.Value) {
        return object;
    }

    return {
        Value: null
    };

}

export default extractDataFromResult;