import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';

// import { Icon } from '@material-ui/core';
import styled from 'styled-components'
import StyledHeader, { StyledHeaderWrapper, MenuButton, Highlight, ChangeDossierButton } from './header.styled.js';
import logo from '../../../assets/images/moneto-logo.svg';
import avatar from '../../../assets/images/avatar.png';
import { HeaderButtonPrimary, HeaderButtonSecondary, LangButton, Icon, NormalButton } from '../styled';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../breadcrumb';
import OpenedMenu, { FullMenuLink } from './Openedmenu.component';
import { setLogin, clearKey } from '../../../redux/actions';
import { Dossier } from '../../../redux/initialState';
import authService from '../../../services/authService';
import UserPanel from '../userPanel';
import dossierProvider from '../../../providers/dossierProvider';
import { authorizedRoutes, unAuthorizedRoutes, helpRoutes } from '../../../constants/routes';


const Header: React.FC<any> = ({ isLoggedIn, state, history, setLogin, clearKey }) => {
  const { t, i18n } = useTranslation(['header', 'routes']);
  const [langInit, setLangInit ] = useState(false);
  
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    
    localStorage.setItem('language', language);
    
    if(!langInit) { 
      setLangInit(true); 
    }
    else { 
      // window.location.reload();
    }
  };
  const [previousLoggedIn, setPrevious] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const languages = ['de', 'en'];
  const name: string = state.myPerson.fullName() || state.myPerson.email || t(`userNameDefault`);
  const [isUserPanelOpen, setUserPanelOpen] = useState<boolean>(false);
  const [logoutUrl, setUrl] = useState<string>(t('routes:logout'));
  const togglePanel = () => {
    setUserPanelOpen((open: boolean) => !open);
    if (isOpen) setIsOpen(false);
  };

  useEffect(() => {
    if (isLoggedIn)
      setUrl(
        `${t('routes:logout')}?firstName=${state.myPerson.firstName}${
          state.myPartner.firstName ? `&secondName=${state.myPartner.firstName}` : ''
        }`
      );
    else if (!isLoggedIn && previousLoggedIn) history.push(logoutUrl);
    setPrevious(isLoggedIn);
  }, [isLoggedIn, state.myPartner.firstName, state.myPerson.firstName, history, logoutUrl, previousLoggedIn, t]);

  useEffect(() => {
    setIsOpen(false);
    setUserPanelOpen(false);
  }, [history.location]);

  useEffect(() => {
    if (!localStorage.getItem('language') || localStorage.getItem('language') === null) {
      changeLanguage(languages[0]);
    } else if (languages.indexOf(localStorage.getItem('language') || '') > -1) {
      changeLanguage(localStorage.getItem('language') as string);
    }
  }, []);

  const logout = () => {
    authService.logout(() => {
      setLogin(false);
      clearKey(0);
      clearKey(1);
      clearKey(2);
    });
  };

  const closeDossier = () => {
    dossierProvider.closeDossier((res: any) => {
        authService.redirectToProMode();
      },
      (err: any) => console.log(err)
    );
  };

  const renderLanguages = () => languages.map((lang, index) => (
    <div key={lang.toString()}>
      <LangButton
        className={lang === i18n.language ? 'active' : ''}
        key={lang}
        onClick={() => changeLanguage(lang)}>
        {lang}
      </LangButton>
      {index===0 && <span style={{ color: 'white', marginRight: 5 }}>/</span>}
    </div>
  ))

  const renderLoginButtons = () => <React.Fragment>
    <Link to={t('routes:login')} className="login-button">
      <HeaderButtonPrimary>{t('loginBtn')}</HeaderButtonPrimary>
    </Link>
    <Link to={t('routes:register')} className="register-button">
      <HeaderButtonSecondary>{t('registerBtn')}</HeaderButtonSecondary>
    </Link>
  </React.Fragment>

  const renderAuthorizedRoutes = () => isLoggedIn && authorizedRoutes && authorizedRoutes.length > 0 &&
    authorizedRoutes.map((route: any, index: number) => {
      if(route.subRoutes && route.subRoutes.length > 0) 
      {
        return route.subRoutes.map((subRoute: any, index: number) => (
          <NormalButton onClick={ () => history.push(t(`routes:${route.keyName}`)) } key={subRoute.keyName}>
            <Link to={t(`routes:${subRoute.keyName}`)}>{t(`subLinks.${subRoute.keyName}`)}</Link>
              <span>{subRoute.description}</span>
            {t(`mainLinks.${route.keyName}`)}
          </NormalButton>
        ))
      }
      return (
        <NormalButton onClick={ () => history.push(t(`routes:${route.keyName}`)) } key={route.keyName}>
          {t(`mainLinks.${route.keyName}`)}
        </NormalButton>
      )
    })

  const renderUnAuthorizedRoutes = () => !isLoggedIn && unAuthorizedRoutes && unAuthorizedRoutes.length > 0 &&
  unAuthorizedRoutes.map((route: any, index: number) => {
    if(route.subRoutes && route.subRoutes.length > 0) 
    {
      return route.subRoutes.map((subRoute: any, index: number) => (
        <a onClick={ () => history.push(t(`routes:${route.keyName}`)) }>
          <Link to={t(`routes:${subRoute.keyName}`)}>{t(`subLinks.${subRoute.keyName}`)}</Link>
            <span>{subRoute.description}</span>
          {t(`mainLinks.${route.keyName}`)}
        </a>
      ))
    }
    return (
      <a onClick={ () => history.push(t(`routes:${route.keyName}`)) }>
        {t(`mainLinks.${route.keyName}`)}
      </a>
    )
  })

  const renderHelpRoutes = () =>  helpRoutes && helpRoutes.length > 0 && (
    helpRoutes.map((item: any, index: number) => {

      if(isLoggedIn) {
        return item.showAuthenticated && (
          <a key={index} onClick={() => history.push(t(`routes:${item.keyName}`))}>
            {t(`helpLinks.${item.keyName}`)}
          </a>
        )
      }

      return <a key={index} onClick={() => history.push(t(`routes:${item.keyName}`))}>
        {t(`helpLinks.${item.keyName}`)}
      </a>
    })
  )


  const renderUserWithPanel = () => (
    <>
    <div className="swissre-my-profile" onClick={togglePanel}>
      <span>
        {state.account.is_specialist === 1 ? state.account.full_name || state.account.username : name}
      </span>
      <img src={avatar} alt="avatar" width="40" />
    </div>
      <Highlight display={isUserPanelOpen ? 'block' : 'none'} />
    <UserPanel
      isOpen={isUserPanelOpen}
      toggle={togglePanel}
      logout={logout}
      fullName={state.account.is_specialist === 1 ? state.account.full_name : name}
      email={state.account.mail}
      isMyOffice={state.account.is_specialist === 1}
      ownDossier={state.account.own_dossier}
    />
  </>
  )

  return (
    <>
      <label htmlFor="menu-checkbox" className='menu-hamburger-button'>
        <Icon content="menu"/>
      </label>
      <input type='checkbox' id="menu-checkbox"/>
      <div className='swissre-header-container'>
        <div className='swissre-header-logo-container'>
          <Link className="logo-link" to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={'swissre-navigation'}>
          <div className={'swissre-help-routes'}>
            {renderHelpRoutes()}
          </div>
          <div className={'swissre-account-routes'}>
              <div className='swissre-languages-container'>
                {renderLanguages()}
              </div>
              <div className='swissre-logins-container'>
                {!isLoggedIn && renderLoginButtons()}
                {isLoggedIn && <div> {renderUserWithPanel()} </div> }
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: Dossier) => ({
    isLoggedIn: state.authInfo.loggedIn,
    state 
})
  
const mapDispatchToProps = (dispatch: any) => ({
    setLogin: (isLoggedIn: boolean) => dispatch(setLogin(isLoggedIn)),
    clearKey: (target: number) => dispatch(clearKey(target))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
